import {Component, HostListener, Injector, OnInit} from '@angular/core';
import {ModalPageController} from '../../Core/modal-page-controller/modal-page-controller';
import {LineOrderDto, OrdersDto} from '../../../Services/Models/orders-model.service';
import {IonItemSliding, NavParams} from '@ionic/angular';
import {LoaderManagerService} from '../../../Services/Components/loader-manager.service';
import {OrdersApiService} from '../../../Services/Api/orders-api.service';
import {CustomString} from '../../../Utils/strings';
import {ProductsSupplierDto} from '../../../Services/Models/products-supplier-model.service';
import {TraceabilityPage} from '../../Ingredients/traceability/traceability.page';
import {CustomNumber} from '../../../Utils/numbers';
import {PrinterIntegrationService} from '../../../Services/Integrations/integrations-printer.service';
import {FixDevicesModelService} from '../../../Services/Models/fix-devices-model.service';
import {DevicesApiService} from '../../../Services/Api/devices-api.service';
import {FiltersDto, FiltersModelsService} from '../../../Services/Models/filters-models.service';
import {IntegrationsScalesService} from '../../../Services/Integrations/integrations-scales.service';
import {TraceabilityModesConst} from '../../../Utils/Const/traceability-modes.const';
import {TraceabilityModesEnum} from '../../../Utils/Enum/traceability-modes.enum';

@Component({
  selector: 'app-set-product-order',
  templateUrl: './set-product-order.page.html',
  styleUrls: ['./set-product-order.page.scss'],
})
export class SetProductOrderPage extends ModalPageController implements OnInit {

  order: OrdersDto;
  product: LineOrderDto;
  productsSupplier: ProductsSupplierDto[];
  isEditing: boolean;
  scales: any[];
  printers: any[];
  filters: FiltersDto;

  traceabilityModesList = TraceabilityModesConst;
  traceabilityModesEnum = TraceabilityModesEnum;

  totalCantTraz: number;

  searchValue: string;

  intervalFocus: number;
  activateQrReader = false;

  disabledCloseModal = false;

  form: {
    cantidad_total: number;
    cantidad: number;
    cods_trazabilidad: {
      id: number;
      codigo: string;
      cantidad: number;
      highlight?: boolean;
    }[];
  };

  constructor(
    protected injector: Injector,
    protected navParams: NavParams,
    protected loadCtrl: LoaderManagerService,
    protected printerService: PrinterIntegrationService,
    protected devicesService: DevicesApiService,
    protected scalesService: IntegrationsScalesService,
    protected fixDevicesModel: FixDevicesModelService,
    protected filtersModel: FiltersModelsService,
    protected ordersApiService: OrdersApiService
  ) {
    super(injector);
  }

  get showScale(): boolean {
    const purchaseFormat = this.product.formato_compra?.toString()?.toLowerCase();

    return !this.product.formato_multiplo && ((this.product.unidad === 'KG' || this.product.unidad === 'GR') &&
      (!this.product.formato_compra || purchaseFormat === 'kilo' || purchaseFormat === 'kg'));
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(value): void {

    if (!this.product || !this.activateQrReader) {
      return;
    }

    this.qrReaderService.getValueQrReader(value.key).then((data) => {
      this.getWeightScale(data);
    });
  }

  ngOnInit() {
    this.order = this.navParams.get('order');
    this.product = this.navParams.get('line');
    this.productsSupplier = this.navParams.get('productsSupplier');
    this.isEditing = this.navParams.get('isEditing');
    this.filters = this.navParams.get('filters');
    this.scales = this.navParams.get('scales');
    this.printers = this.navParams.get('printers');

    if (this.product) {
      this.initForm();
    }

    this.checkFocus();

    setTimeout(() => {
      this.setFocusInHighlightItem();
    }, 750);
  }


  /**
   * Poner el foco en el item destacado
   *
   */
  setFocusInHighlightItem() {
    const element = document.querySelector<HTMLInputElement>('.highlight > .quantity-wrapper > ion-input > input');

    if (element) {
      element.focus();
    }
  }


  /**
   * Comprueba si hay algún foco activo en un ion-input de la vista, si no hay, establece el foco en el input del lector123456bb
   *
   */
  checkFocus() {
    this.intervalFocus = setInterval(() => {
      const elements = document.querySelectorAll('ion-input');
      const elementsArray = Array.from(elements);

      const hasFocus = elementsArray.some((element) => element.classList.contains('has-focus'));

      this.activateQrReader = !hasFocus;
    }, 1000);
  }


  /**
   * Inicializar formulario
   *
   */
  initForm() {

    const quantity = this.product.cantidad_original && this.filters.modo_pedidos === this.traceabilityModesEnum.supermarket
      ? this.product.cantidad_supermercado
      : this.product.cantidad;

    this.form = {
      cantidad_total: quantity,
      cantidad: this.product.formato_multiplo ? Number((quantity / this.product.formato_multiplo).toFixed(2)) : quantity,
      cods_trazabilidad: []
    };

    this.product.cods_trazabilidad?.forEach((item) => {
      this.form.cods_trazabilidad.push(item);
    });

    this.checkCodTrazQuantity();
    this.setTotalTraz();
  }


  /**
   * Asignar a la variable el producto seleccionado
   *
   */
  selectProduct(product) {
    this.product = product;
    this.initForm();
  }


  /**
   * Establecer la cantidad con sus formatos
   *
   */
  setQuantity() {
    this.form.cantidad = CustomString.replaceComa(this.form.cantidad);

    if (!this.form.cantidad && this.form.cantidad !== 0) {
      this.form.cantidad = 1;
      this.form.cantidad_total = this.form.cantidad;

      this.toastCtrl.showWarningToast('La cantidad debe de ser un campo numérico!');
      return;
    }

    if (this.product.formato_minimo && this.form.cantidad < this.product.formato_minimo) {
      this.form.cantidad = this.product.formato_minimo;

      const item = this.form.cods_trazabilidad.find((cod) => cod.cantidad < this.product.formato_minimo);

      if (item) {
        item.cantidad = Number(item.cantidad);
        item.cantidad = item.cantidad + (this.product.formato_minimo - this.totalCantTraz);
      }

      this.toastCtrl.showWarningToast('Tiene que tener un mínimo de ' + this.product.formato_minimo);
    }

    if (this.product.formato_multiplo) {
      this.form.cantidad_total = Number((this.product.formato_multiplo * this.form.cantidad).toFixed(2));

    } else {
      this.form.cantidad_total = this.form.cantidad;
    }
  }


  /**
   * Abrir modal de los lotes de trazabilidad
   *
   */
  async openModalTraceability(cod) {

    if (cod.codigo) {
      return;
    }

    this.activateQrReader = false;
    clearInterval(this.intervalFocus);

    const modal = await this.modalCtrl.create({
      component: TraceabilityPage,
      componentProps: {
        idProduct: this.product.id_producto,
        filters: this.filters,
      },
      backdropDismiss: false,
      cssClass: 'force-normal'
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        cod.codigo = data.data.codigo || data.data;
      }

      this.checkFocus();
    });

    await modal.present();
  }


  /**
   * Establecer modo
   *
   */
  async setMode() {
    const alert = await this.alertCtrl.create({
      header: '¡Advertencia!',
      message: 'Si cambias de modo perderás estos cambios. ¿Deseas continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: async () => {
            this.filters = await this.filtersModel.list();
          }
        },
        {
          text: 'Continuar',
          handler: () => {
            this.filtersModel.save(this.filters);

            this.disabledCloseModal = true;
            this.initForm();
          }
        }
      ]
    });

    await alert.present();
  }


  /**
   * Añadir línea código de trazabilidad
   *
   */
  addCodTrazabiliad(quantity?: any, code?: string) {
    const hasIncompleteLine = this.form.cods_trazabilidad.some((cod) => !cod.codigo && !cod.cantidad);

    if (!quantity && !code && hasIncompleteLine) {
      this.toastCtrl.showWarningToast('No se pueden añadir más líneas si hay alguna incompleta');
      return;
    }

    let incompleteLine = null;

    if (quantity || code) {
      incompleteLine = this.form.cods_trazabilidad.find((cod) => (quantity && !cod.cantidad) || (code && !cod.codigo));

      if (!incompleteLine) {
        this.addNewLine(quantity, code);
        return;
      }

      if (quantity) {
        incompleteLine.cantidad = quantity;
      }

      if (code) {
        incompleteLine.codigo = code;
      }

      this.setTotalTraz();
      return;
    }

    this.addNewLine(quantity, code);
  }


  /**
   * Añadir una nueva linea
   *
   */
  addNewLine(quantity?: any, code?: string) {
    const newItem = {
      id: null,
      codigo: code || null,
      cantidad: quantity || null
    };

    this.form.cods_trazabilidad.unshift(newItem);
    this.setTotalTraz();
  }


  /**
   * Confirmar eliminar todos los códigos de trazabilidad
   *
   */
  async confirmDeleteAllTraceability() {
    const alert = await this.alertCtrl.create({
      header: 'Eliminar códigos trazabilidad',
      message: 'Estás a punto de eliminar todos los códigos de trazabilidad. ¿Deseas continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.deleteAllTraceability();
          }
        }
      ]
    });

    await alert.present();
  }


  /**
   * Eliminar todos los código de trazabilidad
   *
   */
  deleteAllTraceability() {
    const traceabilityItems = this.form.cods_trazabilidad.filter((cod) => cod.codigo && cod.cantidad);

    traceabilityItems.forEach((item) => {
      this.deleteCod(item);
    });

    if (this.form.cods_trazabilidad.length === 0) {
      this.addCodTrazabiliad();
    }
  }


  /**
   * Elimina la línea código de trazabilidad
   *
   */
  deleteCod(data, itemSld?: IonItemSliding) {

    if (itemSld) {
      itemSld.close();
    }

    const index = this.form.cods_trazabilidad.indexOf(data);
    this.form.cods_trazabilidad.splice(index, 1);

    this.setTotalTraz();
  }


  /**
   * Cambiar cantidad código de trazabilidad
   *
   */
  changeCantidadTraz(data) {

    if (this.filters?.modo_pedidos === this.traceabilityModesEnum.split) {
      this.filters.modo_pedidos = this.traceabilityModesEnum.default;
    }

    if (data.cantidad) {
      data.cantidad = parseFloat(CustomNumber.splitComaToPunto(data.cantidad.toString()));
    }

    this.setTotalTraz();
  }


  /**
   * Calcular el total de trazabilidad
   *
   */
  setTotalTraz() {

    if (this.form.cantidad < 0) {
      return;
    }

    this.totalCantTraz = 0;

    for (const item of this.form.cods_trazabilidad) {

      if (this.filters?.modo_pedidos === this.traceabilityModesEnum.split) {
        item.cantidad = Number((this.form.cantidad / this.form.cods_trazabilidad.length).toFixed(2));
      }

      this.totalCantTraz += Number(item.cantidad?.toString()) || 0;
    }

    setTimeout(() => {
      this.form.cantidad = Number(this.totalCantTraz.toFixed(2));
      this.setQuantity();
    }, 100);
  }


  /**
   * Comprobar la cantidad total de los códigos de trazabilidad
   *
   */
  checkCodTrazQuantity() {
    let totalQuantityTraz = this.form.cods_trazabilidad?.reduce((sum, cod) => sum + cod.cantidad, 0) ?? 0;
    let quantity = this.form.cantidad;

    const remainingQuantity = quantity - totalQuantityTraz;

    quantity = Number((totalQuantityTraz < quantity ? remainingQuantity : totalQuantityTraz).toFixed(2));
    totalQuantityTraz = Number(totalQuantityTraz.toFixed(2));

    if (this.product.formato_minimo && quantity < this.product.formato_minimo) {
      quantity = this.product.formato_minimo;
    }

    if (totalQuantityTraz !== quantity || quantity === 0) {
      this.addCodTrazabiliad(quantity);
    }
  }


  /**
   * Guardar resultados
   *
   */
  async saveProduct() {

    if (this.form.cantidad_total === 0 && this.filters.modo_pedidos !== this.traceabilityModesEnum.supermarket) {
      this.confirmDeleteProduct('Cantidad inválida', 'No puedes establecer la cantidad de un producto a 0. ¿Deseas eliminarlo?');
      return;
    }

    await this.loadCtrl.show();

    const isSupermarketMode = this.product.cantidad_original && this.filters.modo_pedidos === this.traceabilityModesEnum.supermarket;
    this.product[isSupermarketMode ? 'cantidad_supermercado' : 'cantidad'] = this.form.cantidad_total;

    this.product.cods_trazabilidad = this.form.cods_trazabilidad.filter((cod) => cod.codigo && cod.cantidad);

    let response;

    if (this.isEditing) {

      response = await this.ordersApiService.setQuantityProduct(this.product)
        .finally(() => {
          this.loadCtrl.hide();
        });

    } else {

      this.product.id_pedido = this.order?.id;

      response = await this.ordersApiService.addProductOrder(this.product)
        .finally(() => {
          this.loadCtrl.hide();
        });
    }

    if (response.data) {
      this.closeModal(response.data, this.isEditing ? 'edit' : 'new');
    }

    this.toastCtrl.showToastByStatusCode(response.data, response.message);

  }


  /**
   * Confirmar eliminar producto
   *
   */
  async confirmDeleteProduct(header: string, message: string) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Eliminar',
          handler: () => {
            this.deleteProduct();
          }
        }
      ]
    });

    await alert.present();
  }


  /**
   * Eliminar producto
   *
   */
  deleteProduct() {
    this.loadCtrl.show();

    this.ordersApiService.deleteProductOrderApi({id: this.product.id_linea}).then((response) => {

      if (response.data) {
        this.closeModal(response.data, 'delete');
      }

      this.toastCtrl.showToastByStatusCode(response.data, response.message);

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }


  /**
   * Confirmar impresora
   *
   */
  async confirmPrinter(item, itemSld: IonItemSliding) {

    await itemSld.close();

    const fixDevices = await this.fixDevicesModel.list();

    // Comprobar si hay una impresora fijada
    if (fixDevices?.printer) {
      this.printCod(item, fixDevices.printer.ip);

      // Comprobar si hay una impresoras en ese local
    } else if (this.printers.length > 0) {

      if (this.printers.length > 1) {
        const options = {
          header: 'Seleccionar impresora',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel'
            },
            {
              text: 'OK',
              handler: (ip) => {
                this.printCod(item, ip);
              }
            }
          ],
          inputs: []
        };

        for (const imp of this.printers) {
          options.inputs.push({
            type: 'radio',
            label: imp.nombre,
            value: imp.ip,
            checked: false
          });
        }

        const alerts = await this.alertCtrl.create(options);
        await alerts.present();

      } else {
        this.printCod(item, this.printers[0].ip);
      }

    } else {
      this.toastCtrl.showWarningToast('Ups, parece que no tienes ninguna impresora configurada en este local');
    }
  }


  /**
   * Imprimir etiqueta trazabilidad
   *
   */
  printCod(item, ip: string) {

    const data = {
      codigo: item.codigo,
      nombre: this.product.nombre,
      subtitulo: this.order.proveedor,
      unidad: this.product.unidad,
      cantidad: item.cantidad,
      fecha: this.order.fecha.original
    };

    this.printerService.productoPedido(data, ip);
  }


  /**
   * Obtener el peso de la báscula y añadirlo a la línea de trazabilidad
   *
   */
  getWeightScale(code?: string) {

    let quantity: number = null;

    if (this.scales.length === 0 || !this.showScale || this.filters.modo_pedidos !== this.traceabilityModesEnum.supermarket) {

      if (this.traceabilityModesEnum.supermarket === this.filters.modo_pedidos) {
        quantity = 1;
      }

      this.addCodTrazabiliad(quantity, code);
      return;
    }

    this.scalesService.getWeightFromScale(this.scales, this.product.unidad, false).then((data) => {
      this.addCodTrazabiliad(data, code);
    });
  }


  /**
   * Cerrar vista modal
   *
   */
  closeModal(data: any = null, role: string = '') {
    clearInterval(this.intervalFocus);
    this.modalCtrl.dismiss(data, role);
  }

}
