import {NgModule} from '@angular/core';
import {SearchPipe} from './Search/search.pipe';
import {SortPipe} from './List/sort.pipe';
import {LocationsPipe} from './Locations/locations.pipe';
import {TurnsPipe} from './Turns/turns.pipe';
import {ListPipe} from './List/list.pipe';
import {SearchProductionPipe} from './Search/search-production.pipe';
import {MiseEnPlacePipe} from './List/mise-en-place.pipe';
import {OrderListPipe} from './Order/order-list.pipe';
import {ProductsOrderPipe} from './Order/products-order.pipe';
import {ProductsListPipe} from './Order/products-list.pipe';

const declarationsExports = [
  SearchPipe,
  SortPipe,
  LocationsPipe,
  TurnsPipe,
  ListPipe,
  SearchProductionPipe,
  MiseEnPlacePipe,
  OrderListPipe,
  ProductsOrderPipe
];

@NgModule({
  imports: [],
  declarations: [...declarationsExports, ProductsListPipe],
  exports: [...declarationsExports, ProductsListPipe],
})
export class PipesModule {
}
