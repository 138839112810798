import {TraceabilityModesEnum} from '../Enum/traceability-modes.enum';

export const TraceabilityModesConst = [
  {
    id: TraceabilityModesEnum.default,
    nombre: 'Por defecto'
  },
  {
    id: TraceabilityModesEnum.supermarket,
    nombre: 'Supermercado'
  },
  {
    id: TraceabilityModesEnum.split,
    nombre: 'Fraccionar'
  }
];
