import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {LineOrderDto, OrdersDto} from '../../../Services/Models/orders-model.service';
import {OrderStatusEnum} from '../../../Utils/Enum/order-status.enum';
import {FiltersDto} from '../../../Services/Models/filters-models.service';
import {TraceabilityModesEnum} from '../../../Utils/Enum/traceability-modes.enum';
import {ToastManagerService} from '../../../Services/Components/toastManager.service';
import * as moment from 'moment';

@Component({
  selector: 'app-order-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['./order-products.component.scss'],
})
export class OrderProductsComponent implements OnInit {

  @Input() user: any;
  @Input() order: OrdersDto;
  @Input() item: LineOrderDto;
  @Input() filters: FiltersDto;
  @Input() statusOrder: number;

  @Output() onClickCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickQuantity: EventEmitter<any> = new EventEmitter<any>();

  statusOrderEnum = OrderStatusEnum;
  traceabilityModesEnum = TraceabilityModesEnum;

  constructor(private toastCtrl: ToastManagerService) {
  }

  get quantity(): number {
    return this.item.formato_multiplo
      ? Number((this.item.cantidad / this.item.formato_multiplo).toFixed(2))
      : (this.item.cantidad || 0);
  }

  get supermarketQuantity(): number {
    return this.item.formato_multiplo
      ? Number((this.item.cantidad_supermercado / this.item.formato_multiplo).toFixed(2))
      : (this.item.cantidad_supermercado || 0);
  }


  ngOnInit() {
    this.checkSupermarketQuantity();
  }


  /**
   * Comprobar si la cantidad supermercado es la misma que la cantidad
   *
   */
  checkSupermarketQuantity() {
    if (this.filters.modo_pedidos !== this.traceabilityModesEnum.supermarket) {
      return;
    }

    this.item.check_preparacion = this.supermarketQuantity === this.quantity;
    this.setCheckbox(false);
  }


  /**
   * Marcar listo el producto
   *
   */
  setCheckbox(emit = true) {
    this.item.usuario = this.item.check_preparacion ? this.user.full_name : null;
    this.item.updated = this.item.check_preparacion ? moment().format('DD/MM/YYYY HH:mm') : null;

    if (emit) {
      this.onClickCheckbox.emit();
    }
  }


  /**
   * Mostrar mensaje para confirmar el producto
   *
   */
  showMessageToCheckProduct() {
    if (this.filters?.modo_pedidos === this.traceabilityModesEnum.supermarket && this.supermarketQuantity === 0) {
      this.toastCtrl.showWarningToast('No se pueden confirmar productos a cero, debes introducir una cantidad o eliminar el producto del pedido');
    }
  }

}
