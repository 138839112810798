import {Injector} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ActionSheetController,
  AlertController,
  MenuController,
  ModalController,
  NavController,
  Platform,
  PopoverController
} from '@ionic/angular';
import {ToastManagerService} from '../../../Services/Components/toastManager.service';
import {LoaderManagerService} from '../../../Services/Components/loader-manager.service';
import {RoutesEnum} from '../../../Utils/Enum/routes.enum';
import {IonicEventsService} from '../../../Services/Components/ionic-events.service';
import {PusherEventsService} from '../../../Services/Components/pusher-events.service';
import {TextToSpeechService} from '../../../Services/Components/text-to-speech.service';
import {DataManagerService} from '../../../Services/Components/data-manager.service';
import {MultisessionManagerService} from '../../../Services/Components/multisession-manager.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IntegrationsQrReaderService} from '../../../Services/Integrations/integrations-qr-reader.service';
import {PermissionsManagerService} from '../../../Services/Components/permissions-manager.service';
import {AuthService} from '../../../Services/Api/auth.service';
import {AnimationsManagerService} from '../../../Services/Components/animations-manager.service';
import {PrintTableManagerService} from '../../../Services/Components/print-table-manager.service';

export abstract class PageController {

  public routesEnum = RoutesEnum;
  public multisessionCtrl: MultisessionManagerService;
  public permissionsManager: PermissionsManagerService;
  protected authApi: AuthService;
  protected navCtrl: NavController;
  protected toastCtrl: ToastManagerService;
  protected loadCtrl: LoaderManagerService;
  protected activatedRoute: ActivatedRoute;
  protected modalCtrl: ModalController;
  protected alertCtrl: AlertController;
  protected popoverCtrl: PopoverController;
  protected actionSheetCtrl: ActionSheetController;
  protected eventCtrl: IonicEventsService;
  protected pusherCtrl: PusherEventsService;
  protected speakCtrl: TextToSpeechService;
  protected dataManagerService: DataManagerService;
  protected printTableManager: PrintTableManagerService;
  protected sanitizer: DomSanitizer;
  protected menuCtrl: MenuController;
  protected platform: Platform;
  protected router: Router;
  protected qrReaderService: IntegrationsQrReaderService;
  protected animationCtrl: AnimationsManagerService;

  protected constructor(
    protected injector: Injector,
  ) {
    this.navCtrl = this.injector.get<NavController>(NavController);
    this.toastCtrl = this.injector.get<ToastManagerService>(ToastManagerService);
    this.loadCtrl = this.injector.get<LoaderManagerService>(LoaderManagerService);
    this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.modalCtrl = this.injector.get<ModalController>(ModalController);
    this.alertCtrl = this.injector.get<AlertController>(AlertController);
    this.popoverCtrl = this.injector.get<PopoverController>(PopoverController);
    this.actionSheetCtrl = this.injector.get<ActionSheetController>(ActionSheetController);
    this.eventCtrl = this.injector.get<IonicEventsService>(IonicEventsService);
    this.pusherCtrl = this.injector.get<PusherEventsService>(PusherEventsService);
    this.speakCtrl = this.injector.get<TextToSpeechService>(TextToSpeechService);
    this.dataManagerService = this.injector.get<DataManagerService>(DataManagerService);
    this.printTableManager = this.injector.get<PrintTableManagerService>(PrintTableManagerService);
    this.multisessionCtrl = this.injector.get<MultisessionManagerService>(MultisessionManagerService);
    this.sanitizer = this.injector.get<DomSanitizer>(DomSanitizer);
    this.menuCtrl = this.injector.get<MenuController>(MenuController);
    this.platform = this.injector.get<Platform>(Platform);
    this.router = this.injector.get<Router>(Router);
    this.qrReaderService = this.injector.get<IntegrationsQrReaderService>(IntegrationsQrReaderService);
    this.permissionsManager = this.injector.get<PermissionsManagerService>(PermissionsManagerService);
    this.animationCtrl = this.injector.get<AnimationsManagerService>(AnimationsManagerService);
    this.authApi = this.injector.get<AuthService>(AuthService);
  }

  exitApp(path: string, ...params) {
    this.navCtrl.navigateRoot(path, {
      queryParams: this.getParamsObject(params),
      queryParamsHandling: 'merge'
    });

    this.dataManagerService.deleteData();
  }

  goTo(path: string, ...params) {
    this.navCtrl.navigateForward(path, {
      animated: true,
      queryParams: this.getParamsObject(params),
      queryParamsHandling: 'merge'
    });
  }

  closeModal(data = null, role = '') {
    this.modalCtrl.dismiss(data, role);
  }

  protected getParamsObject(params) {

    if (!params) {
      return null;
    }

    return params[0];
  }

  protected cleanParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {}
    });
  }

}
