import {Pipe, PipeTransform} from '@angular/core';
import {ProductsOrderDto} from '../../Services/Models/products-orders-model.service';
import {OrdersDto} from '../../Services/Models/orders-model.service';

@Pipe({
  name: 'productsList'
})
export class ProductsListPipe implements PipeTransform {

  transform(items: ProductsOrderDto[], orders: any, pendentProducts: boolean) {

    if (items) {

      if (pendentProducts) {

        const pendentProductsIds: number[] = orders.flatMap((order: OrdersDto) =>
          order.lineas
            .filter((line) => !line.check_preparacion)
            .map((line) => line.id));

        items = items.filter((item) => pendentProductsIds.includes(item.id));

      }

      return items;
    }

  }

}
