import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-data-block',
  templateUrl: './data-block.component.html',
  styleUrls: ['./data-block.component.scss'],
})
export class DataBlockComponent {

  @Input() date: string | number | Date;
  @Input() deliveryDate: string | number | Date;
  @Input() local: string;
  @Input() user: string;
  @Input() state: string;
  @Input() colorState: string;

}
