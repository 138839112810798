import {Component, Injector, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {UserSessionService} from '../../../Services/Models/user-session.service';
import {PageController} from '../../../Pages/Core/page-controller/page-controller';
import {AuthService} from '../../../Services/Api/auth.service';
import {FiltersDto, FiltersModelsService} from '../../../Services/Models/filters-models.service';
import {FamiliesDto} from '../../../Services/Models/families-model.service';
import {EstablishmentsDto} from '../../../Services/Models/establishments-model.service';
import {TurnsDto} from '../../../Services/Models/turns-model.service';
import {LocationsDto} from '../../../Services/Models/locations-model.service';
import {ApiResponse} from '../../../Services/Api/api.service';
import {FamiliesApiService} from '../../../Services/Api/families-api.service';
import {EstablishmentsApiService} from '../../../Services/Api/establishments-api.service';
import {TurnsApiService} from '../../../Services/Api/turns-api.service';
import {LocationsApiService} from '../../../Services/Api/locations-api.service';
import {ShipmentsDto} from '../../../Services/Models/stocks-model.service';
import {StocksApiService} from '../../../Services/Api/stocks-api.service';
import {Router} from '@angular/router';
import {SetDevicesPage} from '../../../Pages/Configuration/set-devices/set-devices.page';
import {FixDevicesModelService} from '../../../Services/Models/fix-devices-model.service';
import {SetMultisessionPage} from '../../../Pages/Configuration/set-multisession/set-multisession.page';
import {DepartmentDto} from '../../../Services/Models/departments-model.service';
import {DepartmentsApiService} from '../../../Services/Api/departments-api.service';
import {OrdersApiService} from '../../../Services/Api/orders-api.service';
import {OrderStatusConst} from '../../../Utils/Const/order-status.const';
import {App} from '@capacitor/app';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent extends PageController implements OnInit {

  user: any;

  families: FamiliesDto[];
  familiesMise: FamiliesDto[];
  establishments: EstablishmentsDto[];
  establishmentsOrders: EstablishmentsDto[] = [];
  departments: DepartmentDto[];
  turns: TurnsDto[];
  locations: LocationsDto[];
  shipments: ShipmentsDto[];
  orderStatus: { id: number; nombre: string }[];

  filters: FiltersDto;
  version: string;

  constructor(
    protected injector: Injector,
    protected filtersModel: FiltersModelsService,
    protected popoverCtrl: PopoverController,
    protected familiesService: FamiliesApiService,
    protected establishmentsService: EstablishmentsApiService,
    protected departmentsService: DepartmentsApiService,
    protected turnsService: TurnsApiService,
    protected locationsService: LocationsApiService,
    protected userSession: UserSessionService,
    protected authApi: AuthService,
    protected stocksService: StocksApiService,
    protected ordersService: OrdersApiService,
    protected fixDevicesModel: FixDevicesModelService,
    public router: Router
  ) {
    super(injector);

    this.user = this.userSession.get();
    this.getVersion();
  }

  async ngOnInit() {
    this.getData();
    this.getFilters();
  }


  /**
   * Obtener versión
   *
   */
  getVersion() {
    App.getInfo().then((data) => {
      this.version = data.version;

    }).catch(() => {
      this.version = packageJson.version;
      console.error('Error: Version not implemented on web.');
    });
  }


  /**
   * Obtener filtros
   *
   */
  async getFilters() {
    this.filters = await this.filtersModel.list();
  }


  /**
   * Guardar filtros
   *
   */
  setFilters() {
    this.filtersModel.save(this.filters);
  }


  /**
   * Listar datos
   *
   */
  async getData() {
    this.orderStatus = OrderStatusConst;
    this.establishments = await this.establishmentsService.model.list();
    this.locations = await this.locationsService.model.list();
    this.departments = await this.departmentsService.model.list();
    this.families = await this.familiesService.model.list();

    if (this.router.url === '/app/tabs/orders') {
      const orders = await this.ordersService.model.list();
      this.filterEstablishmentsOrders(orders);
    }

    this.getTurns();

    if (this.router.url === '/app/tabs/home') {
      this.getMiseEnPlace();
    }
  }


  /**
   * Obtener turnos
   *
   */
  getTurns() {
    this.turnsService.getTurnsApi(this.filters.id_local).then((response: ApiResponse) => {
      this.turns = response.data;
    });
  }


  /**
   * Obtener familias y partidas de la ubicación seleccionada (Mise en place)
   *
   */
  getMiseEnPlace() {

    // Familias
    this.stocksService.getMiseEnPlaceFamiliesApi(this.filters).then((response: ApiResponse) => {
      this.familiesMise = response.data;
    });


    // Partidas
    this.stocksService.getMiseEnPlaceShipmentsApi(this.filters).then((response: ApiResponse) => {
      this.shipments = response.data;
    });
  }


  /**
   * Filtrar locales de los pedidos
   *
   */
  filterEstablishmentsOrders(orders) {
    if (!orders || orders.length === 0) {
      this.establishmentsOrders = [];
      return;
    }

    this.establishmentsOrders = orders
      .map(item => ({id: item.id_local, nombre: item.local}))
      .filter((value, index, self) => index === self.findIndex(t => t.id === value.id && t.nombre === value.nombre));
  }


  /**
   * Obtener los turnos del local seleccionado
   *
   */
  filterEstablishment() {

    this.eventCtrl.publish({
      name: 'filter:establishment',
      data: this.filters
    });

    this.getTurns();

    // Si solo hay una ubicación en el local seleccionado se asignará automáticamente
    const filterLocations = this.locations.filter(item => item.id_local === this.filters.id_local);

    if (filterLocations?.length === 1) {
      this.filters.id_ubicacion = filterLocations[0].id;

      this.filterLocation();
    }

    // Borrar los dispositivos fijados
    this.fixDevicesModel.delete();
    this.eventCtrl.publish({name: 'scale:fixed'});

    this.setFilters();

  }


  /**
   * Ejecutar evento de la ubicación seleccionada
   *
   */
  filterLocation(loadData = true) {

    if (this.router.url === '/app/tabs/home') {

      if (loadData) {
        this.getMiseEnPlace();
      }

      this.eventCtrl.publish({
        name: 'miseEnPlace:action',
        data: this.filters
      });
    }

    if (this.router.url === '/app/tabs/recipes') {
      this.eventCtrl.publish({
        name: 'recetario:action',
        data: this.filters
      });
    }

    if (this.router.url === '/app/tabs/recipes-costing') {
      this.eventCtrl.publish({
        name: 'recipesCosting:action',
        data: this.filters
      });
    }

    if (this.router.url === '/app/tabs/productions') {
      this.eventCtrl.publish({
        name: 'production:action',
        data: this.filters
      });
    }

    if (this.router.url === '/app/tabs/orders') {
      this.eventCtrl.publish({
        name: 'orders:action',
        data: this.filters
      });
    }

    this.setFilters();

  }


  /**
   * Filtrar por familia
   *
   */
  filterFamily() {

    this.eventCtrl.publish({
      name: 'family:filter',
      data: this.filters
    });

    this.setFilters();

  }


  /**
   * Filtrar por familia mise en place
   *
   */
  filterFamilyMiseEnPlace() {

    this.eventCtrl.publish({
      name: 'familyMiseEnPlace:filter',
      data: this.filters
    });

    this.setFilters();

  }


  /**
   * Filtrar por partidas
   *
   */
  filterShipments() {

    this.eventCtrl.publish({
      name: 'shipments:filter',
      data: this.filters
    });

    this.setFilters();

  }


  /**
   * Abrir vista de dispositivos
   *
   */
  async openDevices() {
    const modal = await this.modalCtrl.create({
      component: SetDevicesPage,
      backdropDismiss: false,
      swipeToClose: true
    });

    modal.onDidDismiss().then((data) => {
    });

    return await modal.present();
  }


  /**
   * Configuración multisesión
   *
   */
  async setMultisession() {
    const modal = await this.modalCtrl.create({
      component: SetMultisessionPage,
      backdropDismiss: false,
      swipeToClose: true
    });

    modal.onDidDismiss().then((data) => {
      if (data?.data) {
        this.filters = data?.data;
      }
    });

    return await modal.present();
  }


  /**
   * Alerta para eliminar cuenta
   *
   */
  async confirmDeleteAccount() {
    const alert = await this.alertCtrl.create({
      header: 'Eliminar cuenta',
      message: 'Estás a punto de eliminar tu cuenta de usuario. Borraremos todos tus datos de nuestro servidor, ' +
        'esta acción no es reversible. Para confirmar escribe tu contraseña',
      inputs: [
        {
          name: 'password',
          placeholder: 'Escribe tu contraseña',
          type: 'password',
          value: '',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Si, eliminar',
          role: 'destructive',
          handler: () => {
            this.toastCtrl.showWarningToast('Tu cuenta quedará totalmente eliminada en 24/48h. Te enviaremos un email confirmando la operación');

            setTimeout(() => {
              this.logout();
            }, 1500);
          },
        },
      ],
    });

    await alert.present();
  }


  /**
   * Alerta para cerrar sesión
   *
   */
  async showAlertLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Cerrar sesión',
      message: 'Estás a punto de cerrar la sesión. ¿Quieres continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Sí, cerrar',
          role: 'destructive',
          handler: () => {
            this.logout();
          }
        }]
    });

    await alert.present();
  }


  /**
   * Cerrar sesión
   *
   */
  logout() {
    this.loadCtrl.show();

    this.authApi.logout(this.user).then(() => {
      if (this.filters.id_ubicacion) {
        this.pusherCtrl.disconnect('ubicacion.', this.filters.id_ubicacion);
      }

      this.exitApp(this.routesEnum.exit);

      this.popoverCtrl.dismiss(null, 'logout');

      setTimeout(() => {
        window.location.reload();
      }, 100);

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }

}
