import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintTableManagerService {

  /**
   * Imprimir tabla
   *
   */
  public printTable(title: string, contentId: string) {
    const popupWin = window.open('_blank', '');
    const printContents = document.getElementById(contentId).innerHTML;

    popupWin.document.open();

    popupWin.document.write(`
        <html lang="es">
            <head>
                <title>` + title + `</title>
                <style>
                    .header {
                      text-align: center;
                    }

                    .table {
                      width: 100%;
                    }

                    th {
                      padding: 10px;

                      text-align: left;
                      font-weight: bold;

                      border-bottom: 2px solid #ccc;
                    }

                    td {
                      padding: 10px;
                      border-bottom: 1px dashed #eee;
                    }

                    .date-table {
                      font-size: 12px;
                    }
                </style>
            </head>

            <body onload=window.print();window.close()>` + printContents + `</body>
        </html>
    `);

    popupWin.document.close();
  }

}
