import {Pipe, PipeTransform} from '@angular/core';
import {LineOrderDto} from '../../Services/Models/orders-model.service';
import {ProductsOrderDto} from '../../Services/Models/products-orders-model.service';

@Pipe({
  name: 'productsOrder'
})
export class ProductsOrderPipe implements PipeTransform {

  transform(items: LineOrderDto[], product: ProductsOrderDto, pendentsProducts: boolean) {

    if (items) {

      if (product) {
        items = items.filter((item) => product.id === item.id);
      }

      if (pendentsProducts) {
        items = items.filter((item) => !item.check_preparacion);
      }

      return items;
    }

  }

}
