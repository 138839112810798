import {Component, Input} from '@angular/core';
import {ProductsOrderDto} from '../../../Services/Models/products-orders-model.service';

@Component({
  selector: 'app-products-order-table',
  templateUrl: './products-order-table.component.html',
})
export class ProductsOrderTableComponent {
  @Input() products: ProductsOrderDto[];

  date: Date = new Date();
}
