import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FiltersModelsService} from '../Models/filters-models.service';
import {UsersListPage} from '../../Pages/Users/users-list/users-list.page';
import {IonicEventsService} from './ionic-events.service';

@Injectable({
  providedIn: 'root'
})
export class MultisessionManagerService {

  modalMultisession: HTMLIonModalElement;

  timeMultisession: any;
  intervalTime: any;

  constructor(
    protected modalCtrl: ModalController,
    protected eventCtrl: IonicEventsService,
    protected filtersService: FiltersModelsService
  ) {
  }


  /**
   * Seleccionar usuario
   *
   */
  async selectUserMultisession(fromButton: boolean = false) {
    const filters = await this.filtersService.list();

    if (filters?.multisesion?.multisesion_active && (filters?.multisesion?.auto_lock || fromButton)) {
      this.modalMultisession = await this.modalCtrl.create({
        component: UsersListPage,
        backdropDismiss: false,
        cssClass: 'force-fullscreen'
      });

      this.modalMultisession.onDidDismiss().then((data) => {
        if (data.data) {
          this.multisessionTime();

          this.eventCtrl.publish({
            name: 'multisesion:user',
            data: data.data
          });
        }

        this.modalMultisession = null;
      });

      await this.modalMultisession.present();
    }
  }


  /**
   * Comprobar tiempo multisesión
   *
   */
  checkMultisessionTime() {
    this.multisessionTime();

    this.destroyInterval();

    this.intervalTime = setInterval(() => {
      let now = new Date();

      if ((this.timeMultisession < now.getTime()) && !this.modalMultisession) {
        this.selectUserMultisession();
      }
    }, 5000);
  }


  /**
   * Destruir intervalo
   *
   */
  destroyInterval() {
    clearInterval(this.intervalTime);
    this.intervalTime = null;
  }


  /**
   * Tiempo multisesión
   *
   */
  multisessionTime() {
    const date = new Date();
    this.timeMultisession = date.getTime() + 300000; // 5 minutos
  }

}
