import {Injectable} from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
import {ModalController} from '@ionic/angular';
import {AddMoreInfoTagPage} from '../../Pages/Tags/add-more-info-tag/add-more-info-tag.page';
import {EstablishmentsApiService} from '../Api/establishments-api.service';
import {LabelsTypeEnum} from '../../Utils/Enum/labels-type.enum';
import {OrdersDto} from '../Models/orders-model.service';
import {AddContinuedInfoPage} from '../../Pages/Tags/add-continued-info/add-continued-info.page';

@Injectable({
  providedIn: 'root'
})
export class PrinterIntegrationService {

  forzarPrefix: string = 'http://';
  forzarIp: string = '';
  forzarRuta: string = '/pstprnt';

  constructor(
    public modalCtrl: ModalController,
    protected establishmentsService: EstablishmentsApiService
  ) {
  }


  /**
   * Manda los datos del producto de un pedido para su impresión
   *
   */
  productoPedido(data, ip: string, impresiones = 1) {

    let corte = 18;

    const titulo = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '*');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    const subtitulo = data.subtitulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '*');

    corte = this.setWordsCut(palabrasTitulo, corte);

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion';

    zpl += '^FO15,' + (tituloLength > corte ? 10 : 25) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO15,55^APN,50,50^FD' + (titulo.substr(corte)) + '^FS';

    zpl += '^FO15,' + (tituloLength > corte ? 115 : 100) + '^AQN,10,10^FD' + subtitulo + '^FS';

    zpl += '^FO15,' + (tituloLength > corte ? 145 : 130) + '^AQN,10,10^FDCod.: ' + data.codigo + '^FS';

    zpl += '^FO15,180^AQN,10,10^FDCantidad^FS';
    zpl += '^FO140,180^AQN,10,10^FDFecha^FS';

    zpl += '^FO15,205^AQN,50,50^FD' + data.cantidad + '^FS';
    zpl += '^FO135,205^AQN,50,50^FD' + moment(data.fecha).format('DD/MM/YYYY') + '^FS';

    zpl += '^FX seccion de codigo de barras';

    zpl += '^BY3,5,40';
    zpl += '^FO360,125^BQ,70,4^FDQA,' + data.codigo + '^FS';

    zpl += '^PQ' + impresiones;

    zpl += '^XZ';

    this.sendToPrint(ip, zpl);
  }


  /**
   * Imprimir los datos del pedido
   *
   */
  async pedido(data: OrdersDto, ip: string, num: number, total: number, showTotal: boolean) {

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion';
    zpl += '^FO15,25^APN,30,30^FD' + 'Pedido #' + data.referencia + '^FS';

    zpl += '^FO15,90^AQN,10,10^FD' + data.proveedor + '^FS';
    zpl += '^FO15,120^AQN,10,10^FD' + data.local + '^FS';

    zpl += '^FO15,170^AQN,10,10^FD' + data.usuario + '^FS';
    zpl += '^FO15,200^AQN,10,10^FD' + data.fecha.ver + '^FS';

    if (showTotal) {
      zpl += '^FO385,190^APN,30,30^FD' + num + '/' + total + '^FS';
    }

    zpl += '^XZ';

    this.sendToPrint(ip, zpl);
  }


  /**
   * Manda los datos de la etiqueta a la impresora para su impresion
   *
   */
  async etiqueta(data, ip: string, num = 1, idLocal: number) {

    const locales = await this.establishmentsService.model.list();
    const modelo = locales?.find((item) => item.id === idLocal)?.tipo_etiqueta?.id;

    let zpl = '';

    data.num_imp = num;

    switch (modelo) {
      case LabelsTypeEnum.sencilla:
        zpl = this.etiquetaSencilla(data);
        this.sendToPrint(ip, zpl);
        break;

      case LabelsTypeEnum.avanzada:
        await this.openModalAddMoreInfoTag(data, ip);
        break;

      case LabelsTypeEnum.completa:
        await this.openModalAddMoreInfoTag(data, ip, true);
        break;

      case LabelsTypeEnum.completaContinua:
        await this.openModalAddContinuedInfoTag(data, ip);
        break;

      case LabelsTypeEnum.sencillaLote:
        await this.openModalAddMoreInfoTag(data, ip, false, true);
        break;

      case LabelsTypeEnum.completaDirecta:

        if (data.info_nutricional) {
          this.etiquetaNutricional(data, ip);
          return;
        }

        zpl = this.etiquetaCompleta(data);
        this.sendToPrint(ip, zpl);
        break;
    }
  }


  /**
   * Modelo de la etiqueta sencilla
   *
   */
  etiquetaSencilla(data) {

    let corte = 18;
    moment.locale('es');

    const produccion = moment(data.fecha_fin || data.fecha_impresion);
    const caducidad = moment(data.fecha_cad || data.fecha_caducidad);

    const titulo = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    corte = this.setWordsCut(palabrasTitulo, corte);

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta sencilla';

    if (data.muestra) {
      zpl += '^LRY';
      zpl += '^GB500,40,40^FS';
      zpl += '^FO110,15^A0N,20,20^FDM U E S T R A   T E S T I G O^FS';
      zpl += '^LRN';
    }

    zpl += '^FO15,' + (tituloLength > corte ? 35 : 50) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO15,85^APN,50,50^FD' + (titulo.substr(corte)) + '^FS';

    zpl += '^FO15,150^AQN,10,10^FDElaborado^FS';
    zpl += '^FO15,185^ARN,10,10^FD' + ((data.usuario).slice(0, 11)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS'; // 9
    zpl += '^FO15,220^ARN,10,10^FD' + produccion.format('DD/MM/YYYY') + '^FS';

    zpl += '^FO180,150^AQN,10,10^FDCaducidad^FS';
    zpl += '^FO180,185^ARN,10,10^FD' + (caducidad.format('dddd')).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase() + '^FS';
    zpl += '^FO180,220^ARN,10,10^FD' + caducidad.format('DD/MM/YYYY') + '^FS';

    zpl += '^FX seccion de codigo de barras';
    zpl += '^FO350,120^BY4,2,10^BQ,2,4^FDQA,' + data.referencia + '^FS';

    zpl += '^FO335,225^AQN,10,10^FD' + data.referencia + '^FS';

    zpl += '^PQ' + data.num_imp;

    zpl += '^XZ';

    return zpl;
  }


  /**
   * Abrir modal para meter más info a la etiqueta
   *
   */
  async openModalAddMoreInfoTag(data, ip: string, completed = false, onlyLot = false) {
    const modal = await this.modalCtrl.create({
      component: AddMoreInfoTagPage,
      componentProps: {
        item: data,
        onlyLot
      }
    });

    modal.onDidDismiss().then((response) => {
      const res = response.data;

      if (!res) {
        return false;
      }

      data.lote = res ? res.lote : '';
      data.caducidad_primaria = res ? res.caducidad_primaria : null;
      data.caducidad_primaria_has_time = res ? res.caducidad_primaria_has_time : false;
      data.caducidad_primaria_time = res ? res.caducidad_primaria_time : null;

      data.congelacion = res ? res.is_congelacion : false;

      data.descongelacion = res ? res.is_descongelacion : false;
      data.tiempo_descongelacion = res ? res.tiempo_descongelacion : 0;

      data.apertura = res ? res.is_apertura : false;
      data.tiempo_apertura = res ? res.tiempo_apertura : 0;

      data.num_imp = res ? res.num_imp : 1;

      if (data.info_nutricional) {
        this.etiquetaNutricional(data, ip);
        return;
      }

      let zpl = '';

      if (completed) {
        zpl = this.etiquetaCompleta(data);

      } else if (onlyLot) {
        zpl = this.etiquetaSencillaLote(data);

      } else {
        zpl = this.etiquetaAvanzada(data);
      }

      this.sendToPrint(ip, zpl);

    });

    await modal.present();
  }


  /**
   * Modelo de la etiqueta sencilla con lote
   *
   */
  etiquetaSencillaLote(data) {

    let corte = 18;
    moment.locale('es');

    const produccion = moment(data.fecha_fin || data.fecha_impresion);
    const caducidad = moment(data.fecha_cad || data.fecha_caducidad);

    const titulo = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    corte = this.setWordsCut(palabrasTitulo, corte);

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta sencilla con lote';

    if (data.muestra) {
      zpl += '^LRY';
      zpl += '^GB500,40,40^FS';
      zpl += '^FO110,15^A0N,20,20^FDM U E S T R A   T E S T I G O^FS';
      zpl += '^LRN';
    }

    zpl += '^FO15,' + (tituloLength > corte ? 28 : 45) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO15,70^APN,50,50^FD' + (titulo.substr(corte)) + '^FS';

    zpl += '^FO15,' + (tituloLength > corte ? 125 : 115) + '^AQN,10,10^FDLote: ' + data.lote + '^FS';

    zpl += '^FO15,155^AQN,10,10^FDElaborado^FS';
    zpl += '^FO15,185^ARN,10,10^FD' + ((data.usuario).slice(0, 11)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    zpl += '^FO15,220^ARN,10,10^FD' + produccion.format('DD/MM/YYYY') + '^FS';

    zpl += '^FO180,155^AQN,10,10^FDCaducidad^FS';
    zpl += '^FO180,185^ARN,10,10^FD' + (caducidad.format('dddd')).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase() + '^FS';
    zpl += '^FO180,220^ARN,10,10^FD' + caducidad.format('DD/MM/YYYY') + '^FS';

    zpl += '^FX seccion de codigo de barras';
    zpl += '^FO350,120^BY4,2,10^BQ,2,4^FDQA,' + data.referencia + '^FS';

    zpl += '^FO335,225^AQN,10,10^FD' + data.referencia + '^FS';

    zpl += '^PQ' + data.num_imp;

    zpl += '^XZ';

    return zpl;
  }


  /**
   * Modelo de la etiqueta avanzada
   *
   */
  etiquetaAvanzada(data) {

    let corte = 14;
    moment.locale('es');

    const titulo = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    corte = this.setWordsCut(palabrasTitulo, corte);

    let fechaCongelacion = moment(data.fecha_fin);

    let fechaDecongelacion = data.descongelacion ? moment(data.fecha_fin).format('DD/MM/YYYY HH:mm') : '';
    let fechaInicioDeUso = data.descongelacion ? moment(data.fecha_fin).add(data.tiempo_descongelacion, 'hours') : moment(data.fecha_fin);
    let fechaCaducidadPrimaria = data.caducidad_primaria ? moment(data.caducidad_primaria).format('DD/MM/YYYY') : '';

    if (fechaCaducidadPrimaria && data.caducidad_primaria_has_time && data.caducidad_primaria_time) {

      let fechaCadAndTime = data.caducidad_primaria + ' ' + data.caducidad_primaria_time + ':00';
      fechaCaducidadPrimaria = moment(fechaCadAndTime).format('DD/MM/YYYY HH:mm');
    }

    let fechaCaducidad = data.fecha_cad ? moment(data.fecha_cad) : moment(data.fecha_caducidad);

    if (data.descongelacion) {

      fechaCaducidad.add(data.tiempo_descongelacion, 'hours');

    } else if (data.apertura) {

      let tiempo_apertura_hours = data.tiempo_apertura * 24;
      fechaCaducidad = moment(data.fecha_fin).add(tiempo_apertura_hours, 'hours');
    }

    let primariaEsMenor = false;

    if (data.caducidad_primaria && fechaCaducidad && moment(data.caducidad_primaria) < fechaCaducidad) {
      primariaEsMenor = true;
      fechaCaducidad = moment(data.caducidad_primaria);
    }

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta avanzada';

    if (data.muestra) {
      zpl += '^LRY';
      zpl += '^GB340,40,40^FS';
      zpl += '^FO50,15^A0N,20,20^FDM U E S T R A   T E S T I G O^FS';
      zpl += '^LRN';
    }

    zpl += '^FO25,' + (tituloLength > corte ? 35 : 45) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO25,80^APN,50,50^FD' + (titulo.substr(corte, 14)) + '^FS';

    zpl += '^FO25,150^AQN,10,10^FDCaducidad primaria^FS';
    zpl += '^FO25,190^ARN,10,10^FD' + (fechaCaducidadPrimaria) + '^FS';

    zpl += '^FO260,150^AQN,10,10^FDLote^FS';
    zpl += '^FO260,190^ARN,10,10^FD' + (data.lote || '') + '^FS';


    zpl += '^FO25,250^AQN,10,10^FD' + (data.descongelacion ? 'Fecha descongelacion' : '') + '^FS';
    zpl += '^FO25,290^ARN,10,10^FD' + fechaDecongelacion + '^FS';

    zpl += '^FO260,250^AQN,10,10^FDEmpleado^FS';
    zpl += '^FO260,290^ARN,10,10^FD' + ((data.usuario).slice(0, 15)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';


    if (data.descongelacion) {
      zpl += '^FO25,355^AQN,10,10^FDInicio de uso^FS';

    } else {
      zpl += '^FO25,355^AQN,10,10^FD' + (data.apertura ? 'Apertura envase' : 'Fecha produccion') + '^FS';
    }

    zpl += '^FO25,385^ARN,10,10^FD' + (!data.congelacion ? fechaInicioDeUso.format('DD/MM/YYYY HH:mm') : '') + '^FS';


    if (data.congelacion) {
      zpl += '^FO260,355^AQN,10,10^FDFecha congelacion^FS';
      zpl += '^FO260,385^ARN,10,10^FD' + ((fechaCongelacion.format('dddd')).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase()) + '^FS';
      zpl += '^FO260,420^ARN,10,10^FD' + fechaCongelacion.format('DD/MM/YYYY HH:mm') + '^FS';

    } else {
      zpl += '^FO260,355^AQN,10,10^FDFecha caducidad^FS';
      zpl += '^FO260,385^ARN,10,10^FD' + ((fechaCaducidad.format('dddd')).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase()) + '^FS';
      zpl += '^FO260,420^ARN,10,10^FD' + (primariaEsMenor && fechaCaducidadPrimaria ? fechaCaducidadPrimaria : fechaCaducidad.format('DD/MM/YYYY HH:mm')) + '^FS';
    }


    zpl += '^FX seccion de codigo de barras';
    zpl += '^FO355,10^BY4,2,10^BQN,2,4^FDQA,' + data.referencia + '^FS';

    zpl += '^FO355,110^AQN,10,10^FD' + data.referencia + '^FS';

    zpl += '^FX seccion de cuadros';

    zpl += '^FO1,1^GB490,140,1^FS';
    zpl += '^FO1,1^GB340,140,1^FS';

    zpl += '^FO1,140^GB490,105,1^FS';
    zpl += '^FO1,244^GB490,105,1^FS';
    zpl += '^FO1,348^GB490,120,1^FS';

    zpl += '^FO1,140^GB245,328,1^FS';

    zpl += '^PQ' + data.num_imp;
    zpl += '^XZ';

    return zpl;
  }


  /**
   * Modelo de la etiqueta completa
   *
   */
  etiquetaCompleta(data, ip = '') {

    moment.locale('es');

    let corte = 14;
    let corteDireccion = 40;

    const titulo: string = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    corte = this.setWordsCut(palabrasTitulo, corte);

    const direccion: string = data.datos_empresa && data.datos_empresa.direccion
      ? data.datos_empresa.direccion.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '')
      : '';
    const palabrasDireccion = direccion.split(' ');

    corteDireccion = this.setWordsCut(palabrasDireccion, corteDireccion);

    let alergenos = data.alergenos_nombres || '-';
    const alergenosLength = alergenos.length;
    let corteFila = 0;
    let corteFila2 = 0;

    if (alergenosLength >= 46) {
      const strAux = alergenos.slice(0, 46);
      corteFila = strAux.lastIndexOf(' ') + 1;
    }

    if ((alergenosLength > 46 && alergenosLength < 107) || alergenosLength > 107) {
      const strAux2 = alergenos.slice(corteFila, 107);

      if (alergenosLength > 107) {
        corteFila2 = strAux2.lastIndexOf(' ') + 1 + corteFila;
      }
    }

    if (alergenosLength > 165) {
      alergenos = alergenos.slice(0, 165) + '...';
    }

    let ingredientes = data.ingredientes_nombres || '-';
    const ingredientesLength = ingredientes.length;
    let corteFilaIng = 0;
    let corteFilaIng2 = 0;
    let corteFilaIng3 = 0;
    let corteFilaIng4 = 0;
    let corteFilaIng5 = 0;
    let corteFilaIng6 = 0;
    let corteFilaIng7 = 0;

    if (ingredientesLength >= 45) {
      const strAux = ingredientes.slice(0, 45);
      corteFilaIng = strAux.lastIndexOf(' ') + 1;
    }

    if ((ingredientesLength > 45 && ingredientesLength < 105) || ingredientesLength > 105) {
      const strAux2 = ingredientes.slice(corteFilaIng, 105);

      if (ingredientesLength > 105) {
        corteFilaIng2 = strAux2.lastIndexOf(' ') + 1 + corteFilaIng;
      }
    }

    if ((ingredientesLength > 105 && ingredientesLength < 165) || ingredientesLength > 165) {
      const strAux3 = ingredientes.slice(corteFilaIng2, 165);

      if (ingredientesLength > 165) {
        corteFilaIng3 = strAux3.lastIndexOf(' ') + 1 + corteFilaIng2;
      }
    }

    if ((ingredientesLength > 165 && ingredientesLength < 225) || ingredientesLength > 225) {
      const strAux4 = ingredientes.slice(corteFilaIng3, 225);

      if (ingredientesLength > 225) {
        corteFilaIng4 = strAux4.lastIndexOf(' ') + 1 + corteFilaIng3;
      }
    }

    if ((ingredientesLength > 225 && ingredientesLength < 285) || ingredientesLength > 285) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 285);

      if (ingredientesLength > 285) {
        corteFilaIng5 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if ((ingredientesLength > 285 && ingredientesLength < 345) || ingredientesLength > 345) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 345);

      if (ingredientesLength > 345) {
        corteFilaIng6 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if ((ingredientesLength > 345 && ingredientesLength < 405) || ingredientesLength > 405) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 405);

      if (ingredientesLength > 405) {
        corteFilaIng7 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if (ingredientesLength > 465) {
      ingredientes = ingredientes.slice(0, 465) + '...';
    }

    const fechaCongelacion = moment(data.fecha_fin);

    const fechaDecongelacion = data.descongelacion ? moment(data.fecha_fin).format('DD/MM/YYYY HH:mm') : '';
    const fechaInicioDeUso = data.descongelacion ? moment(data.fecha_fin).add(data.tiempo_descongelacion, 'hours') : moment(data.fecha_fin);
    let fechaCaducidadPrimaria = data.caducidad_primaria ? moment(data.caducidad_primaria).format('DD/MM/YYYY') : '';

    if (fechaCaducidadPrimaria && data.caducidad_primaria_has_time && data.caducidad_primaria_time) {

      const fechaCadAndTime = data.caducidad_primaria + ' ' + data.caducidad_primaria_time + ':00';
      fechaCaducidadPrimaria = moment(fechaCadAndTime).format('DD/MM/YYYY HH:mm');
    }

    let fechaCaducidad = data.fecha_cad ? moment(data.fecha_cad) : moment(data.fecha_caducidad);

    if (data.descongelacion) {

      fechaCaducidad.add(data.tiempo_descongelacion, 'hours');

    } else if (data.apertura) {

      const tiempo_apertura_hours = data.tiempo_apertura * 24;
      fechaCaducidad = moment(data.fecha_fin).add(tiempo_apertura_hours, 'hours');
    }

    let primariaEsMenor = false;

    if (data.caducidad_primaria && fechaCaducidad && moment(data.caducidad_primaria) < fechaCaducidad) {
      primariaEsMenor = true;
      fechaCaducidad = moment(data.caducidad_primaria);
    }

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta completa';

    if (data.muestra) {
      zpl += '^LRY';
      zpl += '^GB340,40,40^FS';
      zpl += '^FO50,15^A0N,20,20^FDM U E S T R A   T E S T I G O^FS';
      zpl += '^LRN';
    }

    zpl += '^FO25,' + (tituloLength > corte ? 35 : 45) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO25,80^APN,50,50^FD' + (titulo.substr(corte, 14)) + '^FS';

    zpl += '^FO25,150^AQN,10,10^FDCaducidad primaria^FS';
    zpl += '^FO25,190^ARN,10,10^FD' + (fechaCaducidadPrimaria) + '^FS';

    zpl += '^FO260,150^AQN,10,10^FDLote^FS';
    zpl += '^FO260,190^ARN,10,10^FD' + (data.lote || data.referencia) + '^FS';


    zpl += '^FO25,250^AQN,10,10^FD' + (data.descongelacion ? 'Fecha descongelacion' : '') + '^FS';
    zpl += '^FO25,290^ARN,10,10^FD' + fechaDecongelacion + '^FS';

    zpl += '^FO260,250^AQN,10,10^FDEmpleado^FS';
    zpl += '^FO260,290^ARN,10,10^FD' + ((data.usuario).slice(0, 15)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';


    if (data.descongelacion) {
      zpl += '^FO25,355^AQN,10,10^FDInicio de uso^FS';

    } else {
      zpl += '^FO25,355^AQN,10,10^FD' + (data.apertura ? 'Apertura envase' : 'Fecha produccion') + '^FS';
    }

    zpl += '^FO25,385^ARN,10,10^FD' + (!data.congelacion ? fechaInicioDeUso.format('DD/MM/YYYY HH:mm') : '') + '^FS';


    if (data.congelacion) {
      zpl += '^FO260,355^AQN,10,10^FDFecha congelacion^FS';
      zpl += '^FO260,385^ARN,10,10^FD' + ((fechaCongelacion.format('dddd')).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase()) + '^FS';
      zpl += '^FO260,420^ARN,10,10^FD' + fechaCongelacion.format('DD/MM/YYYY HH:mm') + '^FS';

    } else {
      zpl += '^FO260,355^AQN,10,10^FDFecha caducidad^FS';
      zpl += '^FO260,385^ARN,10,10^FD' + ((fechaCaducidad.format('dddd')).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase()) + '^FS';
      zpl += '^FO260,420^ARN,10,10^FD' + (primariaEsMenor && fechaCaducidadPrimaria ? fechaCaducidadPrimaria : fechaCaducidad.format('DD/MM/YYYY HH:mm')) + '^FS';
    }

    zpl += '^FO25,480^AQN,10,10^FDAlergenos:^FS';
    zpl += '^FO130,485^APN,10,10^FD' + ((alergenos).slice(0, corteFila || 46)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFila) {
      zpl += '^FO25,510^APN,10,10^FD' + ((alergenos).slice(corteFila, corteFila2 || 107)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFila2) {
      zpl += '^FO25,530^APN,10,10^FD' + ((alergenos).slice(corteFila2, 165)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    zpl += ' ^FO25,597^AQN,10,10^FDIngredientes:^FS';
    zpl += '^FO150,602^APN,10,10^FD' + ((ingredientes).slice(0, corteFilaIng || 45)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFilaIng) {
      zpl += '^FO25,630^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng, corteFilaIng2 || 105)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng2) {
      zpl += '^FO25,650^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng2, corteFilaIng3 || 165)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng3) {
      zpl += '^FO25,670^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng3, corteFilaIng4 || 225)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng4) {
      zpl += '^FO25,690^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng4, corteFilaIng5 || 285)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng5) {
      zpl += '^FO25,710^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng5, corteFilaIng6 || 345)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng6) {
      zpl += '^FO25,730^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng6, corteFilaIng7 || 405)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng7) {
      zpl += '^FO25,750^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng7, 465)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    zpl += '^FO25,800^AQN,10,10^FD' + (data.registro_sanitario ? 'Reg. Sanitario: ' + data.registro_sanitario : '') + '^FS';

    zpl += '^FO25,840^AQN,30,30^FD' + (data.datos_empresa ? data.datos_empresa.nombre : '') + '^FS';
    zpl += '^FO25,870^AQN,10,10^FD' + (data.datos_empresa ? 'CIF: ' + data.datos_empresa.cif : '') + '^FS';
    zpl += '^FO25,900^AQN,10,10^FD' + (direccion.substr(0, corteDireccion)) + '^FS';
    zpl += '^FO25,925^AQN,10,10^FD' + (direccion.substr(corteDireccion, 18)) + '^FS';

    zpl += '^FX seccion de codigo de barras';
    zpl += '^FO355,10^BY4,2,10^BQN,2,4^FDQA,' + data.referencia + '^FS';

    zpl += '^FO355,110^AQN,10,10^FD' + data.referencia + '^FS';

    zpl += '^FX seccion de cuadros';

    zpl += '^FO1,1^GB490,140,1^FS';
    zpl += '^FO1,1^GB340,140,1^FS';

    zpl += '^FO1,140^GB490,105,1^FS';
    zpl += '^FO1,244^GB490,105,1^FS';
    zpl += '^FO1,348^GB490,120,1^FS';
    zpl += '^FO1,467^GB490,110,1^FS';
    zpl += '^FO1,576^GB490,206,1^FS';

    zpl += '^FO1,140^GB245,328,1^FS';

    zpl += '^PQ' + data.num_imp;
    zpl += '^XZ';

    if (ip) {
      this.sendToPrint(ip, zpl);
    }

    return zpl;
  }


  /**
   * Modelo etiqueta nutricional
   *
   */
  etiquetaNutricional(data, ip) {

    const titulo: string = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    let corte = 18;
    corte = this.setWordsCut(palabrasTitulo, corte);

    let modoEmpleo: string = data.modo_empleo
      ? data.modo_empleo.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '')
      : '';
    const modoEmpleoLength = modoEmpleo.length;
    let corteFilaModoEmpleo = 0;
    let corteFilaModoEmpleo2 = 0;

    if (modoEmpleoLength >= 40) {
      const strAux = modoEmpleo.slice(0, 40);
      corteFilaModoEmpleo = strAux.lastIndexOf(' ') + 1;
    }

    if ((modoEmpleoLength > 40 && modoEmpleoLength < 100) || modoEmpleoLength > 100) {
      const strAux2 = modoEmpleo.slice(corteFilaModoEmpleo, 100);

      if (modoEmpleoLength > 100) {
        corteFilaModoEmpleo2 = strAux2.lastIndexOf(' ') + 1 + corteFilaModoEmpleo;
      }
    }

    if (modoEmpleoLength > 160) {
      modoEmpleo = modoEmpleo.slice(0, 160) + '...';
    }

    let corteConservacion = 65;
    const conservacion: string = data.conservacion
      ? data.conservacion.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '')
      : '';
    const palabrasConservacion = conservacion.split(' ');

    corteConservacion = this.setWordsCut(palabrasConservacion, corteConservacion);

    let alergenos = data.alergenos_nombres || '-';
    const alergenosLength = alergenos.length;
    let corteFila = 0;
    let corteFila2 = 0;

    if (alergenosLength >= 46) {
      const strAux = alergenos.slice(0, 46);
      corteFila = strAux.lastIndexOf(' ') + 1;
    }

    if ((alergenosLength > 46 && alergenosLength < 107) || alergenosLength > 107) {
      const strAux2 = alergenos.slice(corteFila, 107);

      if (alergenosLength > 107) {
        corteFila2 = strAux2.lastIndexOf(' ') + 1 + corteFila;
      }
    }

    if (alergenosLength > 165) {
      alergenos = alergenos.slice(0, 165) + '...';
    }

    let ingredientes = data.ingredientes_nombres || '-';
    const ingredientesLength = ingredientes.length;
    let corteFilaIng = 0;
    let corteFilaIng2 = 0;
    let corteFilaIng3 = 0;
    let corteFilaIng4 = 0;
    let corteFilaIng5 = 0;
    let corteFilaIng6 = 0;
    let corteFilaIng7 = 0;

    if (ingredientesLength >= 45) {
      const strAux = ingredientes.slice(0, 45);
      corteFilaIng = strAux.lastIndexOf(' ') + 1;
    }

    if ((ingredientesLength > 45 && ingredientesLength < 105) || ingredientesLength > 105) {
      const strAux2 = ingredientes.slice(corteFilaIng, 105);

      if (ingredientesLength > 105) {
        corteFilaIng2 = strAux2.lastIndexOf(' ') + 1 + corteFilaIng;
      }
    }

    if ((ingredientesLength > 105 && ingredientesLength < 165) || ingredientesLength > 165) {
      const strAux3 = ingredientes.slice(corteFilaIng2, 165);

      if (ingredientesLength > 165) {
        corteFilaIng3 = strAux3.lastIndexOf(' ') + 1 + corteFilaIng2;
      }
    }

    if ((ingredientesLength > 165 && ingredientesLength < 225) || ingredientesLength > 225) {
      const strAux4 = ingredientes.slice(corteFilaIng3, 225);

      if (ingredientesLength > 225) {
        corteFilaIng4 = strAux4.lastIndexOf(' ') + 1 + corteFilaIng3;
      }
    }

    if ((ingredientesLength > 225 && ingredientesLength < 285) || ingredientesLength > 285) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 285);

      if (ingredientesLength > 285) {
        corteFilaIng5 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if ((ingredientesLength > 285 && ingredientesLength < 345) || ingredientesLength > 345) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 345);

      if (ingredientesLength > 345) {
        corteFilaIng6 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if ((ingredientesLength > 345 && ingredientesLength < 405) || ingredientesLength > 405) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 405);

      if (ingredientesLength > 405) {
        corteFilaIng7 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if (ingredientesLength > 465) {
      ingredientes = ingredientes.slice(0, 465) + '...';
    }

    const unidad: string = data.resultados ? data.resultados[0]?.unidad : data.unidad_final;
    const unidadValores: string = unidad === 'LT' ? 'ml' : 'g';

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta nutricional';

    zpl += '^FO25,' + (tituloLength > corte ? 20 : 35) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO25,65^APN,50,50^FD' + (titulo.substr(corte, 14)) + '^FS';

    zpl += '^FO25,135^AQN,10,10^FDInformacion nutricional:^FS';

    zpl += '^FO25,175^AQN,10,10^FDValores medios^FS';
    zpl += '^FO370,175^AQN,10,10^FDPor 100 ' + (unidadValores || '') + '^FS';

    zpl += '^FO25,215^APN,10,10^FDValor energetico / Energia^FS';
    zpl += '^FO355,215^APN,10,10^FD' + (data.valor_energetico || '-') + ' kj / ' + (data.energia || '-') + ' kcal^FS';

    zpl += '^FO25,245^APN,10,10^FDGrasas^FS';
    zpl += '^FO425,245^APN,10,10^FD' + (data.grasas || '-') + ' g^FS';

    zpl += '^FO41,265^APN,10,10^FDde las cuales saturadas^FS';
    zpl += '^FO425,265^APN,10,10^FD' + (data.grasas_saturadas || '-') + ' g^FS';

    zpl += '^FO25,295^APN,10,10^FDHidratos de carbono^FS';
    zpl += '^FO425,295^APN,10,10^FD' + (data.hidratos || '-') + ' g^FS';

    zpl += '^FO41,315^APN,10,10^FDde las cuales azucares^FS';
    zpl += '^FO425,315^APN,10,10^FD' + (data.hidratos_azucares || '-') + ' g^FS';

    zpl += '^FO25,345^APN,10,10^FDProteinas^FS';
    zpl += '^FO425,345^APN,10,10^FD' + (data.proteinas || '-') + ' g^FS';

    zpl += '^FO25,375^APN,10,10^FDSal^FS';
    zpl += '^FO425,375^APN,10,10^FD' + (data.sal || '-') + ' g^FS';


    zpl += '^FO25,415^AQN,10,10^FDModo empleo:^FS';
    zpl += '^FO160,420^APN,10,10^FD' + ((modoEmpleo).slice(0, corteFilaModoEmpleo || 40)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFilaModoEmpleo) {
      zpl += '^FO25,440^APN,10,10^FD' + ((modoEmpleo).slice(corteFilaModoEmpleo, corteFila2 || 100)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaModoEmpleo2) {
      zpl += '^FO25,460^APN,10,10^FD' + ((modoEmpleo).slice(corteFilaModoEmpleo2, 160)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    zpl += '^FO25,525^AQN,10,10^FDAlergenos:^FS';
    zpl += '^FO130,530^APN,10,10^FD' + ((alergenos).slice(0, corteFila || 46)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFila) {
      zpl += '^FO25,555^APN,10,10^FD' + ((alergenos).slice(corteFila, corteFila2 || 107)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFila2) {
      zpl += '^FO25,575^APN,10,10^FD' + ((alergenos).slice(corteFila2, 165)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    zpl += '^FO25,625^AQN,10,10^FDIngredientes:^FS';
    zpl += '^FO150,630^APN,10,10^FD' + ((ingredientes).slice(0, corteFilaIng || 45)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFilaIng) {
      zpl += '^FO25,655^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng, corteFilaIng2 || 105)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng2) {
      zpl += '^FO25,675^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng2, corteFilaIng3 || 165)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng3) {
      zpl += '^FO25,695^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng3, corteFilaIng4 || 225)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng4) {
      zpl += '^FO25,715^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng4, corteFilaIng5 || 285)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng5) {
      zpl += '^FO25,735^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng5, corteFilaIng6 || 345)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng6) {
      zpl += '^FO25,755^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng6, corteFilaIng7 || 405)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng7) {
      zpl += '^FO25,775^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng7, 465)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (conservacion) {
      zpl += '^FO25,815^APN,10,10^FD' + (conservacion.substr(0, corteConservacion)) + '^FS';
      zpl += '^FO25,835^APN,10,10^FD' + (conservacion.substr(corteConservacion, 65)) + '^FS';
    }

    if (data.cantidad_formato) {
      zpl += '^FO25,855^APN,10,10^FDPeso neto:^FS';
      zpl += '^FO25,875^APN,30,30^FD' + data.cantidad_formato + '^FS';
    }

    if (data.grado_alcoholico) {
      zpl += '^FO330,885^AQN,10,10^FDAlc. ' + data.grado_alcoholico + '% vol^FS';
    }

    zpl += '^FO25,925^APN,10,10^FDFabricado por: ' + (data.datos_empresa ? data.datos_empresa.nombre : '-') + '^FS';

    zpl += '^FX seccion de cuadros';
    zpl += '^FO1,1^GB490,125,1^FS'; // Título

    zpl += '^FO1,125^GB490,280,1^FS'; // Info nutricional
    zpl += '^FO1,125^GB490,82,1^FS'; // Cabecera nutricional

    zpl += '^FO1,125^GB490,385,1^FS'; // Modo de empleo

    zpl += '^FO1,510^GB490,100,1^FS'; // Alergenos

    zpl += '^FO1,610^GB490,195,1^FS'; // Ingredientes


    zpl += '^PQ' + data.num_imp;
    zpl += '^XZ';

    this.sendToPrint(ip, zpl);
    this.etiquetaCompleta(data, ip);
  }


  /**
   * Abrir modal para meter más info a la etiqueta
   *
   */
  async openModalAddContinuedInfoTag(data, ip: string) {

    const modal = await this.modalCtrl.create({
      component: AddContinuedInfoPage,
      componentProps: {
        item: data
      }
    });

    modal.onDidDismiss().then((response) => {
      const res = response.data;

      if (!res) {
        return false;
      }

      data.lote = res.lote || '';
      data.fecha_produccion = res.fecha_produccion ? moment(res.fecha_produccion).format('DD/MM/YYYY HH:mm') : '';
      data.fecha_caducidad = res.fecha_caducidad ? moment(res.fecha_caducidad).format('DD/MM/YYYY HH:mm') : '';

      data.fecha_congelacion = res.fecha_congelacion ? moment(res.fecha_congelacion).format('DD/MM/YYYY HH:mm') : '';
      data.fecha_caducidad_congelacion = res.fecha_caducidad_congelacion ? moment(res.fecha_caducidad_congelacion).format('DD/MM/YYYY HH:mm') : '';

      data.fecha_descongelacion = res.fecha_descongelacion ? moment(res.fecha_descongelacion).format('DD/MM/YYYY HH:mm') : '';
      data.fecha_caducidad_descongelacion = res.fecha_caducidad_descongelacion ? moment(res.fecha_caducidad_descongelacion).format('DD/MM/YYYY HH:mm') : '';

      data.num_imp = res.num_imp || 1;

      let zpl = '';

      zpl = this.etiquetaCompletaContinua(data);

      this.sendToPrint(ip, zpl);

    });

    await modal.present();
  }


  /**
   * Modelo de la etiqueta completa continua
   *
   */
  etiquetaCompletaContinua(data) {

    moment.locale('es');

    let corte = 14;

    const titulo: string = data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '');
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    corte = this.setWordsCut(palabrasTitulo, corte);

    let alergenos = data.alergenos_nombres || '-';
    const alergenosLength = alergenos.length;
    let corteFila = 0;
    let corteFila2 = 0;

    if (alergenosLength >= 61) {
      const strAux = alergenos.slice(0, 61);
      corteFila = strAux.lastIndexOf(' ') + 1;
    }

    if ((alergenosLength > 61 && alergenosLength < 122) || alergenosLength > 122) {
      const strAux2 = alergenos.slice(corteFila, 122);

      if (alergenosLength > 122) {
        corteFila2 = strAux2.lastIndexOf(' ') + 1 + corteFila;
      }
    }

    if (alergenosLength > 180) {
      alergenos = alergenos.slice(0, 180) + '...';
    }

    let ingredientes = data.ingredientes_nombres || '-';
    const ingredientesLength = ingredientes.length;
    let corteFilaIng = 0;
    let corteFilaIng2 = 0;
    let corteFilaIng3 = 0;
    let corteFilaIng4 = 0;
    let corteFilaIng5 = 0;

    if (ingredientesLength >= 61) {
      const strAux = ingredientes.slice(0, 61);
      corteFilaIng = strAux.lastIndexOf(' ') + 1;
    }

    if ((ingredientesLength > 61 && ingredientesLength < 122) || ingredientesLength > 122) {
      const strAux2 = ingredientes.slice(corteFilaIng, 122);

      if (ingredientesLength > 122) {
        corteFilaIng2 = strAux2.lastIndexOf(' ') + 1 + corteFilaIng;
      }
    }

    if ((ingredientesLength > 122 && ingredientesLength < 180) || ingredientesLength > 180) {
      const strAux3 = ingredientes.slice(corteFilaIng2, 180);

      if (ingredientesLength > 180) {
        corteFilaIng3 = strAux3.lastIndexOf(' ') + 1 + corteFilaIng2;
      }
    }

    if ((ingredientesLength > 180 && ingredientesLength < 244) || ingredientesLength > 244) {
      const strAux4 = ingredientes.slice(corteFilaIng3, 244);

      if (ingredientesLength > 244) {
        corteFilaIng4 = strAux4.lastIndexOf(' ') + 1 + corteFilaIng3;
      }
    }

    if ((ingredientesLength > 244 && ingredientesLength < 305) || ingredientesLength > 305) {
      const strAux5 = ingredientes.slice(corteFilaIng4, 305);

      if (ingredientesLength > 305) {
        corteFilaIng5 = strAux5.lastIndexOf(' ') + 1 + corteFilaIng4;
      }
    }

    if (ingredientesLength > 366) {
      ingredientes = ingredientes.slice(0, 366) + '...';
    }

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta completa';

    if (data.muestra) {
      zpl += '^LRY';
      zpl += '^GB340,40,40^FS';
      zpl += '^FO50,15^A0N,20,20^FDM U E S T R A   T E S T I G O^FS';
      zpl += '^LRN';
    }

    zpl += '^FO25,' + (tituloLength > corte ? 35 : 45) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO25,80^APN,50,50^FD' + (titulo.substr(corte, 14)) + '^FS';

    zpl += '^FO25,150^AQN,10,10^FDFecha Produccion^FS';
    zpl += '^FO25,190^ARN,10,10^FD' + data.fecha_produccion + '^FS';

    zpl += '^FO260,150^AQN,10,10^FDFecha Caducidad^FS';
    zpl += '^FO260,190^ARN,10,10^FD' + data.fecha_caducidad + '^FS';

    zpl += '^FO25,240^AQN,10,10^FDFecha Congelacion^FS';
    zpl += '^FO25,280^ARN,10,10^FD' + data.fecha_congelacion + '^FS';

    zpl += '^FO260,240^AQN,10,10^FDCad. Congelacion^FS';
    zpl += '^FO260,280^ARN,10,10^FD' + data.fecha_caducidad_congelacion + '^FS';

    zpl += '^FO25,330^AQN,10,10^FDFecha Descongelacion^FS';
    zpl += '^FO25,365^ARN,10,10^FD' + data.fecha_descongelacion + '^FS';

    zpl += '^FO260,330^AQN,10,10^FDCad. Descongelacion^FS';
    zpl += '^FO260,365^ARN,10,10^FD' + data.fecha_caducidad_descongelacion + '^FS';

    zpl += '^FO25,420^AQN,10,10^FDLote^FS';
    zpl += '^FO25,450^ARN,10,10^FD' + data.lote + '^FS';
    // zpl += '^FO25,450^ARN,10,10^FD' + (data.lote || data.referencia) + '^FS';

    zpl += '^FO260,420^AQN,10,10^FDEmpleado^FS';
    zpl += '^FO260,450^ARN,10,10^FD' + ((data.usuario).slice(0, 15)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    zpl += '^FO25,510^AQN,10,10^FDAlergenos^FS';
    zpl += '^FO25,540^APN,10,10^FD' + ((alergenos).slice(0, corteFila || 61)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFila) {
      zpl += '^FO25,560^APN,10,10^FD' + ((alergenos).slice(corteFila, corteFila2 || 122)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFila2) {
      zpl += '^FO25,580^APN,10,10^FD' + ((alergenos).slice(corteFila2, 183)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    zpl += ' ^FO25,645^AQN,10,10^FDIngredientes^FS';
    zpl += '^FO25,675^APN,10,10^FD' + ((ingredientes).slice(0, corteFilaIng || 61)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';

    if (corteFilaIng) {
      zpl += '^FO25,695^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng, corteFilaIng2 || 122)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng2) {
      zpl += '^FO25,715^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng2, corteFilaIng3 || 183)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng3) {
      zpl += '^FO25,735^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng3, corteFilaIng4 || 244)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng4) {
      zpl += '^FO25,755^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng4, corteFilaIng5 || 305)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    if (corteFilaIng5) {
      zpl += '^FO25,775^APN,10,10^FD' + ((ingredientes).slice(corteFilaIng5, 360)).normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '^FS';
    }

    zpl += '^FO25,825^AQN,10,10^FD' + (data.registro_sanitario ? 'Reg. Sanitario: ' + data.registro_sanitario : '') + '^FS';

    zpl += '^FO25,865^AQN,30,30^FD' + (data.datos_empresa ? data.datos_empresa.nombre : '') + '^FS';
    zpl += '^FO25,895^AQN,10,10^FD' + (data.datos_empresa ? 'CIF: ' + data.datos_empresa.cif : '') + '^FS';
    zpl += '^FO25,925^AQN,10,10^FD' + (data.datos_empresa ? data.datos_empresa.direccion : '') + '^FS';

    zpl += '^FX seccion de codigo de barras';
    zpl += '^FO355,10^BY4,2,10^BQN,2,4^FDQA,' + data.referencia + '^FS';

    zpl += '^FO355,110^AQN,10,10^FD' + data.referencia + '^FS';

    zpl += '^FX seccion de cuadros';
    zpl += '^FO1,1^GB490,140,1^FS';
    zpl += '^FO1,1^GB340,140,1^FS';
    zpl += '^FO1,140^GB490,90,1^FS';
    zpl += '^FO1,230^GB490,90,1^FS';
    zpl += '^FO1,320^GB490,90,1^FS';
    zpl += '^FO1,410^GB490,90,1^FS';

    zpl += '^FO1,500^GB490,135,1^FS';
    zpl += '^FO1,635^GB490,180,1^FS';

    zpl += '^FO1,140^GB245,360,1^FS';

    zpl += '^PQ' + data.num_imp;
    zpl += '^XZ';

    return zpl;
  }


  /**
   * Plantilla etiqueta avanzada
   *
   */
  plantillaEtiqueta(data, ip: string, num = 1) {

    let corte = 18;

    const titulo = !data.plantilla_vacia ? data.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\u00b9-\u00be]/g, '') : '';
    const tituloLength = titulo.length;
    const palabrasTitulo = titulo.split(' ');

    corte = this.setWordsCut(palabrasTitulo, corte);

    let zpl = '';

    zpl += '^XA';
    zpl += '^FX Seccion etiqueta avanzada plantilla';
    zpl += '^FO25,' + (tituloLength > corte ? 20 : 40) + '^APN,50,50^FD' + (titulo.substr(0, corte)) + '^FS';
    zpl += '^FO25,65^APN,50,50^FD' + (titulo.substr(corte, 18)) + '^FS';

    zpl += '^FO25,150^AQN,10,10^FDCaducidad primaria^FS';

    zpl += '^FO260,150^AQN,10,10^FDLote^FS';

    zpl += '^FO25,250^AQN,10,10^FD' + (data.descongelacion ? 'Fecha descongelacion' : '') + '^FS';

    zpl += '^FO260,250^AQN,10,10^FDEmpleado^FS';

    if (data.descongelacion) {
      zpl += '^FO25,355^AQN,10,10^FDInicio de uso^FS';

    } else {
      zpl += '^FO25,355^AQN,10,10^FD' + (data.apertura ? 'Apertura envase' : 'Fecha produccion') + '^FS';
    }

    zpl += '^FO260,355^AQN,10,10^FD' + (data.congelacion ? 'Fecha congelacion' : 'Fecha caducidad') + '^FS';

    zpl += '^FX seccion de cuadros';
    zpl += '^FO1,1^GB490,140,1^FS';
    zpl += '^FO1,140^GB490,105,1^FS';
    zpl += '^FO1,244^GB490,105,1^FS';
    zpl += '^FO1,348^GB490,120,1^FS';
    zpl += '^FO1,140^GB245,328,1^FS';

    zpl += '^PQ' + num;

    zpl += '^XZ';

    this.sendToPrint(ip, zpl);
  }


  /**
   * Establecer el corte de la palabra
   *
   */
  setWordsCut(palabras: string[], corte: number) {

    let suma = 0;

    for (const element of palabras) {

      const sumaAux = element.length + 1 + suma;

      if (sumaAux > corte) {

        corte = suma === 0 ? corte : suma;
        break;

      } else {

        suma = sumaAux;
      }

    }

    return corte;
  }


  /**
   * Conectar con la impresora para imprimir la etiqueta
   *
   */
  sendToPrint(ip, zpl) {
    const url = this.forzarPrefix + (this.forzarIp ? this.forzarIp : ip) + this.forzarRuta;
    const method = 'POST';
    const async = true;
    const request = new XMLHttpRequest();

    request.open(method, url, async);
    request.send(zpl);

    setTimeout(() => {
      request.abort();
    }, 1000);
  }

}
