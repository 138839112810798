import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LineOrderDto, OrdersDto} from '../../../Services/Models/orders-model.service';
import {AlertController, IonItemSliding, ModalController} from '@ionic/angular';
import {OrdersApiService} from '../../../Services/Api/orders-api.service';
import {LoaderManagerService} from '../../../Services/Components/loader-manager.service';
import {ToastManagerService} from '../../../Services/Components/toastManager.service';
import {SetProductOrderPage} from '../../../Pages/Orders/set-product-order/set-product-order.page';
import {ProductsOrderDto} from '../../../Services/Models/products-orders-model.service';
import {OrderStatusEnum} from '../../../Utils/Enum/order-status.enum';
import {ProductsSupplierDto} from '../../../Services/Models/products-supplier-model.service';
import {ProductsApiService} from '../../../Services/Api/products-api.service';
import {FiltersDto} from '../../../Services/Models/filters-models.service';
import {TraceabilityModesEnum} from '../../../Utils/Enum/traceability-modes.enum';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {

  @Input() user: any;
  @Input() order: OrdersDto;
  @Input() productSelected: ProductsOrderDto;
  @Input() filters: FiltersDto;
  @Input() showPendentsProducts: boolean;

  @Input() devices: {printer: any[]; scale: any[]};

  @Output() onClickStatus: EventEmitter<number> = new EventEmitter<number>();
  @Output() onClickPrint: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClickQrReader: EventEmitter<OrdersDto> = new EventEmitter<OrdersDto>();

  @Output() refreshData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshOrders: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshProducts: EventEmitter<boolean> = new EventEmitter<boolean>();

  progress = 0;
  statusOrder = OrderStatusEnum;
  traceabilityModesEnum = TraceabilityModesEnum;

  productsSupplier: ProductsSupplierDto[] = [];

  constructor(
    protected modalCtrl: ModalController,
    protected alertCtrl: AlertController,
    protected loadCtrl: LoaderManagerService,
    protected toastCtrl: ToastManagerService,
    protected productsApiService: ProductsApiService,
    protected ordersApiService: OrdersApiService
  ) {
  }

  ngOnInit() {
    this.getProgress();
  }


  /**
   * Guardar el check en el servidor
   *
   */
  setCheckProduct(product: LineOrderDto) {
    this.ordersApiService.setCheckProduct({id: product.id_linea});
    this.getProgress();
  }


  /**
   * Obtener el progreso del pedido
   *
   */
  getProgress() {
    const total = this.order.lineas.length;
    let itemCheck = 0;

    if (total > 0) {
      setTimeout(() => {
        this.order.lineas.forEach((linea) => {
          if (linea.check_preparacion) {
            itemCheck++;
          }
        });

        this.progress = (itemCheck * 100) / total;
      }, 250);
    }
  }


  /**
   * Obtener productos del proveedor
   *
   */
  getProductsSupplier() {
    this.loadCtrl.show();

    this.productsApiService.getProductsSupplierApi({id_local: this.order.id_local}).then((response) => {
      if (response.data) {
        this.productsSupplier = response.data.productos;

        this.productsSupplier.forEach((item) => {
          item.locked = this.order.lineas.some((line) => line.id === item.id);
        });

        this.setProduct();
      }

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }


  /**
   * Añadir/Editar producto del pedido
   *
   */
  async setProduct(line?: LineOrderDto, isEditing: boolean = false, itemSld?: IonItemSliding) {

    if (this.order.id_estado !== this.statusOrder.nuevo) {
      return;
    }

    if (itemSld) {
      await itemSld.close();
    }

    const modal = await this.modalCtrl.create({
      component: SetProductOrderPage,
      componentProps: {
        order: this.order,
        productsSupplier: this.productsSupplier,
        filters: this.filters,
        scales: this.devices.scale,
        printers: this.devices.printer,
        line,
        isEditing
      },
      backdropDismiss: false,
      cssClass: 'force-normal'
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {

        if (data.role === 'new') {
          this.order = data.data;
        }

        if (data.role === 'delete') {
          this.order.lineas = this.order.lineas.filter((item) => item.id_linea !== line.id_linea);
        }

        this.refreshProducts.emit(true);
        this.getProgress();
      }
    });

    await modal.present();
  }


  /**
   * Confirmar para procesar el pedido
   *
   */
  public async confirmOrderProcess() {
    const alert = await this.alertCtrl.create({
      header: 'Procesar pedido',
      message: 'Estás a punto de procesar este pedido. ¿Deseas continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Continuar',
          handler: () => {
            this.onClickStatus.emit(this.statusOrder.nuevo);
          },
        },
      ],
    });

    await alert.present();
  }


  /**
   * Confirmar para revertir el pedido
   *
   */
  public async confirmOrderReverse() {
    const alert = await this.alertCtrl.create({
      header: 'Revertir pedido',
      message: 'Estás a punto de volver al estado anterior. ¿Deseas continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Continuar',
          handler: () => {
            this.onClickStatus.emit(this.statusOrder.enProceso);
          },
        },
      ],
    });

    await alert.present();
  }


  /**
   * Activar/Desactivar lector QR
   *
   */
  setQrReaderInOrder() {

    if (this.order.id_estado !== this.statusOrder.nuevo) {
      return;
    }

    this.order.qr_reader_activated = !this.order.qr_reader_activated;
    this.onClickQrReader.emit(this.order);
  }

}
