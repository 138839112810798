import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ToastManagerService {

  private toast: HTMLIonToastElement | null;

  constructor(private toastCtrl: ToastController) {
  }

  public showToastByStatusCode(data: any, message: string, duration = 2500) {

    if (!data) {
      this.showWarningToast(message, duration);
      return;
    }

    this.showSuccessToast(message, duration);
  }

  public showWarningToast(message: string, duration = 2500) {
    this.showToast(message, duration, 'warning');
  }

  public showSuccessToast(message: string, duration = 2500) {
    this.showToast(message, duration, 'success');
  }

  public showErrorToast(message: string, duration = 2500) {
    this.showToast(message, duration, 'danger');
  }

  public async showToast(message: string, duration = 2500, colorToast: string) {

    if (!!this.toast || duration <= 0) {
      return;
    }

    this.toast = await this.toastCtrl.create({
      message,
      duration,
      position: 'bottom',
      color: colorToast,
      buttons: [
        {
          text: '',
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });

    this.toast.onDidDismiss().then(() => {
      this.toast = null;
    });

    await this.toast.present();
  }
}
