import {Injectable} from '@angular/core';
import {ApiResponse, BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {LastProductionsModelService} from '../Models/last-productions-model.service';
import {ProductionProductsService} from '../Models/production-products.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionsApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public modelProductionsProducts: ProductionProductsService,
    public modelLastProductions: LastProductionsModelService
  ) {
    super(http, userSession);
  }


  public getProductionsProductsApi(idLocal = null, params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      let local = idLocal ? idLocal : '';

      return this.httpBaseCall('production/products/' + local, params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.modelProductionsProducts.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public viewProductionApi(endpoint, params = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getHttpCall(endpoint, params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getLastProductionsApi(params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('productions/list', params, 'post').subscribe((response: any) => {

        if (save) {
          this.data = this.modelLastProductions.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public startProductionRecipeApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/recipe/start', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public startProductionElaborationApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/elaboration/start', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public endProductionRecipeApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/recipe/end', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public endProductionElaborationApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/elaboration/end', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public cancelProductionApi(endpoint: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteHttpCall(endpoint).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public checkProductionApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/active', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public updateElaborationProductionApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/elaboration/update', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public updateRecipeProductionApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/recipe/update', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public startStepProductionApi(id, params: any = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/step/' + id + '/start', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public endStepProductionApi(id, params: any = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('production/step/' + id + '/end', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public deleteIngredientProductionApi(idIng: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteHttpCall('production/ingredient/' + idIng).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public setLoteProductionApi(params: any = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('traceability/batch', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getInfoTag(params): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('productions/find', params, 'post').subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public setInfoTag(params): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('production/update', params, 'post').subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public setProductionRecipeCosting(params): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('production/recipeCosting/produce', params, 'post').subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getProductByCodeInProduction(params: any): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('production/getProductByCode', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public setProductTraceabilityInProduction(params: any): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('production/setProductTraceabilityCode', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

}
