import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsQrReaderService {

  invalidKeys = ['Clear', 'Shift', 'Space', 'Enter'];
  keysValue = '';

  getValueQrReader(key: string): Promise<string> {

    return new Promise((resolve) => {

      if (!key || this.invalidKeys.includes(key)) {
        return;
      }

      this.keysValue += key;

      setTimeout(() => {

        if (this.keysValue) {
          const result = this.keysValue.replace(/['"]/g, '-');
          resolve(result.toUpperCase());
        }

        this.keysValue = '';

      }, 350);

    });

  }

}
