import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../Pipes/pipes.module';
import {DirectivesModule} from '../Directives/directives.module';

import {PopoverComponent} from './Menu/popover/popover.component';
import {ItemsListComponent} from './Items/list-items/items-list.component';
import {ListEmptyComponent} from './Empty/list-empty/list-empty.component';
import {ItemEmptyComponent} from './Empty/item-empty/item-empty.component';
import {IngredientsRecipeComponent} from './Items/ingredients-recipe/ingredients-recipe.component';
import {StepsRecipeComponent} from './Items/steps-recipe/steps-recipe.component';
import {ProductInitialComponent} from './Items/product-initial/product-initial.component';
import {ProductionsItemsComponent} from './Items/productions-items/productions-items.component';
import {ProductFinalComponent} from './Items/product-final/product-final.component';
import {TimerComponent} from './Timer/timer/timer.component';
import {SearchSelectorComponent, SearchSelectorModal} from './Controls/search-selector/search-selector.component';
import {StepsProductionComponent} from './Items/steps-production/steps-production.component';
import {IngredientsProductionComponent} from './Items/ingredients-production/ingredients-production.component';
import {ProductInitialProductionComponent} from './Items/product-initial-production/product-initial-production.component';
import {ProgressBarComponent} from './Progress/progress-bar/progress-bar.component';
import {NotificationsItemsComponent} from './Items/notifications-items/notifications-items.component';
import {CounterInputComponent} from './Controls/counter-input/counter-input.component';
import {ProductFinalProductionComponent} from './Items/product-final-production/product-final-production.component';
import {YuDatetimeComponent, YuDatetimeModal} from './Controls/yu-datetime/yu-datetime.component';
import {MenuAppsComponent} from './Menu/menu-apps/menu-apps.component';
import {OrderItemComponent} from './Orders/order-item/order-item.component';
import {DataBlockComponent} from './Orders/data-block/data-block.component';
import {OrderProductsComponent} from './Orders/order-products/order-products.component';
import {LockScreenComponent} from './Controls/lock-screen/lock-screen.component';
import {ScaleButtonsComponent} from './Controls/scale-buttons/scale-buttons.component';
import {ProductsOrderTableComponent} from './Tables/products-order-table/products-order-table.component';

const declarationsExports = [
  PopoverComponent,
  ItemsListComponent,
  ListEmptyComponent,
  ItemEmptyComponent,
  IngredientsRecipeComponent,
  StepsRecipeComponent,
  ProductInitialComponent,
  ProductionsItemsComponent,
  ProductFinalComponent,
  TimerComponent,
  SearchSelectorComponent,
  SearchSelectorModal,
  StepsProductionComponent,
  IngredientsProductionComponent,
  ProductInitialProductionComponent,
  ProgressBarComponent,
  NotificationsItemsComponent,
  CounterInputComponent,
  ProductFinalProductionComponent,
  YuDatetimeComponent,
  YuDatetimeModal,
  MenuAppsComponent,
  OrderItemComponent,
  DataBlockComponent,
  OrderProductsComponent,
  LockScreenComponent,
  ScaleButtonsComponent,
  ProductsOrderTableComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
  ],
  declarations: [...declarationsExports],
  exports: [...declarationsExports],
})
export class ComponentsModule {
}
