import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-item-empty',
  templateUrl: './item-empty.component.html',
  styleUrls: ['./item-empty.component.scss'],
})
export class ItemEmptyComponent {
  @Input() img: any;
  @Input() icon: string;

  @Input() msg: string;
}
