import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastManagerService} from '../../../Services/Components/toastManager.service';

@Component({
  selector: 'app-product-initial-production',
  templateUrl: './product-initial-production.component.html',
  styleUrls: ['./product-initial-production.component.scss'],
})
export class ProductInitialProductionComponent {
  @Input() elaborationProduction: any;
  @Input() scales: any[] = [];

  @Output() clickAddQuantity: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickProduct: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected toastCtrl: ToastManagerService
  ) {
  }

  get disabledAddQuantity(): boolean {
    return this.elaborationProduction.cods_traz_producto_inicio.length === 0;
  }


  /**
   * Evento de añadir cantida automática
   *
   */
  addQuantity() {

    if (this.disabledAddQuantity) {
      this.toastCtrl.showWarningToast('Para añadir la cantidad automáticamente, se requiere al menos un código de trazabilidad', 4000);
      return;
    }

    this.clickAddQuantity.emit();
  }

}
